.profile-edit-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 64px;
  padding-bottom: 64px;
}

.profile-edit-form {
  width: 100%;
  margin-top: 24px;
}

.profile-photo-preview {
  width: 150px;
  height: 150px;
  margin-bottom: 24px;
  font-size: 3rem;
}

.submit-button {
  margin: 24px 0 16px;
}

.MuiTypography-h5 {
  margin-bottom: 24px;
  font-weight: 600;
}

.MuiFormControl-root {
  margin-bottom: 16px;
}

.MuiInputLabel-root {
  font-weight: 500;
}

.MuiOutlinedInput-root {
  border-radius: 8px;
}

.MuiButton-root {
  text-transform: none;
  font-weight: 600;
  padding: 10px 0;
}

.MuiButton-containedPrimary {
  background-color: #1976d2;
}

.MuiButton-containedPrimary:hover {
  background-color: #115293;
}

.upload-photo-button {
  margin-bottom: 24px;
}

.error-message {
  color: #f44336;
  margin-top: 8px;
  font-size: 0.875rem;
}

@media (max-width: 600px) {
  .profile-edit-container {
    padding-top: 32px;
    padding-bottom: 32px;
  }

  .profile-photo-preview {
    width: 120px;
    height: 120px;
    font-size: 2.5rem;
  }

  .MuiTypography-h5 {
    font-size: 1.5rem;
  }
}