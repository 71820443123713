@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400;500;700&display=swap');

.itys-card {
  font-family: -apple-system, system-ui, BlinkMacSystemFont, Segoe UI, Roboto, Helvetica Neue, Fira Sans, Ubuntu, Oxygen, Oxygen Sans, Cantarell, Droid Sans, Apple Color Emoji, Segoe UI Emoji, Segoe UI Emoji, Segoe UI Symbol, Lucida Grande, Helvetica, Arial, sans-serif;
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.08);
  margin: 4px 0;
  padding: 6px;
}

.itys-header-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  padding-bottom: 4px;
  border-bottom: 1px solid #e0e0e0;
}

.itys-header-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}

.itys-creator {
  display: flex;
  align-items: center;
  gap: 8px;
}

.itys-date {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 0.875rem;
  color: #666666;
}

.creator-avatar {
  width: 40px;
  height: 40px;
  font-size: 1rem;
  background-color: #0a66c2;
}

.creator-name {
  font-size: 1rem;
  font-weight: 600;
  color: #191919;
}

.itys-visibility,
.itys-status {
  font-size: 0.875rem;
  padding: 4px 8px;
  border-radius: 16px;
  font-weight: 600;
}

.itys-visibility {
  background-color: #f3f6f8;
  color: #0a66c2;
}

.itys-status {
  display: flex;
  align-items: center;
  gap: 4px;
}

.status-right {
  background-color: #ecfce5;
  color: #057642;
}

.status-wrong {
  background-color: #fff0f0;
  color: #b74700;
}

.status-pending {
  background-color: #fff8e1;
  color: #915907;
}

.edit-button {
  color: #666666;
}

.itys-main-content {
  padding-bottom: 12px;
  border-bottom: 1px solid #e0e0e0;
}

.itys-title {
  font-size: 1.4rem;
  font-weight: 600;
  color: #191919;
  margin-bottom: 8px;
  line-height: 1.4;
}

.itys-content {
  font-size: 0.875rem;
  color: #191919;
  margin-bottom: 16px;
  line-height: 1.5;
}

.itys-footer-top {
  display: flex;
  justify-content: space-around;
  padding: 3px 0;
  border-bottom: 1px solid #e0e0e0;
}

.group-button {
  display: flex;
  align-items: center;
  gap: 2px;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.8rem;
  color: #666666;
}

.itys-group-details {
    margin-top: 12px;
    padding: 12px;
    background-color: #f3f6f8;
    border-radius: 8px;
  }
  
.group-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}
  
  .group-header h3 {
    font-size: 1rem;
    margin: 0;
  }
  
  .add-member-button {
    padding: 4px !important;
  }
  
  .add-member-button:hover {
    background-color: rgba(10, 102, 194, 0.1) !important;
  }
  
  .avatar-list {
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    margin-top: 4px;
  }

.avatar-item {
  display: flex;
  align-items: center;
  gap: 4px;
}

.avatar {
  width: 24px;
  height: 24px;
  font-size: 0.75rem;
  background-color: #0a66c2;
}

.avatar-name {
  font-size: 0.875rem;
  color: #191919;
}

.add-member-button {
  display: flex;
  align-items: center;
  gap: 4px;
  background-color: #0a66c2;
  color: white;
  border: none;
  padding: 8px 12px;
  border-radius: 16px;
  cursor: pointer;
  margin-top: 8px;
  font-size: 0.875rem;
}

.add-member-button:hover {
  background-color: #004182;
}

.itys-footer-bottom {
  display: flex;
  justify-content: space-around;
  align-items: center;
  padding-top: 2px;
}

.itys-actions {
  display: flex;
  gap: 4px;
}

.action-button {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666666;
  background: none;
  border: none;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 8px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.action-button:hover {
  background-color: #f3f6f8;
}

@media (max-width: 600px) {
  .itys-card {
    padding: 2px;
  }

  .itys-header-top {
    flex-wrap: wrap;
  }

  .itys-header-bottom {
    flex-direction: row;
    align-items: flex-start;
    gap: 4px;
  }

  .itys-date {
    align-self: flex-end;
    font-size: 0.5rem;
    align-items: top;
    gap: 2px;
  }

  .itys-visibility,
  .itys-status {
    font-size: 0.75rem;
    padding: 2px 6px;
  }

  .creator-name {
    font-size: 0.8rem;
  }

  .itys-title {
    font-size: 1.2rem;
  }

  .itys-content,
  .action-button,
  .avatar-name,
  .group-button,
  .itys-date {
    font-size: 0.8rem;
  }

  .itys-footer-top {
    flex-direction: row;
    align-items: flex-start;
    gap: 1px;
  }

  .itys-footer-bottom {
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
  }

  .itys-actions {
    width: 100%;
    justify-content: space-between;
  }

  .avatar {
    width: 20px;
    height: 20px;
    font-size: 0.6875rem;
  }
}