@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:wght@400;700&display=swap');

.app-container {
    min-height: 100vh;
    background: linear-gradient(45deg, #9760a1 30%, #e04478 90%);
    display: flex;
    flex-direction: column;
  }
  
  /* 
  .title-toolbar {
    flex-wrap: wrap;
    justify-content: space-between;
  }
  */
  .title-toolbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
  }

  .app-title {
    font-family: 'Noto Sans', Arial, sans-serif;
    font-weight: bold;
    font-size: clamp(1.5rem, 5vw, 2.5rem);
    text-shadow: 2px 2px 4px rgba(0,0,0,0.3);
  }
  
  .app-banner {
    max-width: 100%;
    height: auto;
    margin-right: auto;
  }

  .main-content {
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-align: center;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: white;
  }
  
  .page-heading {
    font-size: clamp(1.5rem, 4vw, 2.5rem);
  }
  
  .page-subheading {
    font-size: clamp(1rem, 3vw, 1.5rem);
  }
  
  .feature-list {
    font-size: clamp(0.9rem, 2.5vw, 1.1rem);
  }
  
  .get-started-button {
    margin-top: 0.5rem !important;
    align-self: center;
  }
  
  .predictions-showcase {
    margin-top: 0.7rem;
    width: 100%;
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .samples-heading {
    font-size: clamp(1.2rem, 3.5vw, 2rem);
    margin-bottom: 1.5rem;
  }
  
  .prediction-image {
    width: 100%;
    height: auto;
    object-fit: contain;
    max-height: 400px;
  }
  
  .footer {
    padding: 1.5rem 1rem;
    margin-top: auto;
    background-color: rgba(0,0,0,.3);
    color: white;
  }
  
  .footer-buttons {
    margin-top: 0.5rem;
  }
  
  @media (max-width: 600px) {
    .title-toolbar {
      flex-direction: column;
      align-items: center;
    }
  
    .app-title {
      margin-bottom: 1rem;
    }
  
    .main-content {
      margin-top: 1rem;
    }
  
    .predictions-showcase {
      margin-top: 0.5rem;
    }
  }